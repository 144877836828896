import React from 'react';
import { useLogout, useRedirect } from 'react-admin';
import { theme as mytheme } from '../theme';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router-dom';
import { useSignup, useLayer7Notify } from 'layer7-apihub';
import { UnAuthenticatedLayoutWithTheme } from '../layout';
import { SignUpForm } from './SignUpForm';

export const SignUpPage = params => {
    const logout = useLogout();
    const redirect = useRedirect();
    logout();
    const locations = useLocation();
    console.log(locations.hash);
    if (locations.hash.includes('token')) {
        redirect('/account-setup' + locations.hash);
    }
    const classes = useStyles();
    const notify = useLayer7Notify();

    const [signup] = useSignup();

    const handleSubmit = data => {
        signup(data, {
            onSuccess: () => {
                notify('resources.registrations.notifications.confirmation');
            },
            onFailure: error => {
                notify(
                    error || 'resources.registrations.notifications.error',
                    'error'
                );
            },
        });
    };
    return (
        <UnAuthenticatedLayoutWithTheme>
            <Container className={classes.container} maxWidth="lg">
                <div className={classes.root}>
                    <section className={classes.section}>
                        <Typography
                            component="h2"
                            variant="h4"
                            className={classes.title}
                            gutterBottom
                        >
                            Register for an account
                        </Typography>
                        <Typography gutterBottom>
                            The developer portal gives third parties access to
                            our APIs along with a sandbox environment
                            provisioned to help you gain early access and to
                            provide feedback on our integrated solution.
                        </Typography>
                        <Card className={classes.signUp}>
                            <SignUpForm onSubmit={handleSubmit} />
                        </Card>
                    </section>
                </div>
            </Container>
        </UnAuthenticatedLayoutWithTheme>
    );
};

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            background: mytheme.palette.secondary.bgLightBlue,
        },
    },
    root: {
        color: mytheme.palette.common.black,
    },
    container: {},
    title: {
        color: mytheme.palette.secondary.darkBlue,
    },
    button: {},
    section: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(4),
    },
    signUp: {
        marginTop: theme.spacing(4),
    },
}));
