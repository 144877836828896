import React, { cloneElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { HideOnScroll, LoadingIndicator } from 'react-admin';
import {
    ApiHubUserMenu,
    ApiHubLanguageSwitcher,
    SidebarButton,
} from 'layer7-apihub';

import LogoHeaderCsob from '../ui/LogoHeaderCsob.png';

export const AuthenticatedAppBar = ({
    children,
    classes: classesOverride,
    className,
    languagesMenu,
    logo,
    logout,
    open,
    sidebarButton,
    title,
    userMenu,
    ...rest
}) => {
    const classes = useStyles({ classes: classesOverride });
    const whichUsername = localStorage.getItem('username');
    return (
        <HideOnScroll>
            <AppBar
                className={classes.appBar}
                color="secondary"
                elevation={0}
                {...rest}
            >
                <Toolbar
                    disableGutters
                    variant="regular"
                    className={classes.toolbar}
                >
                    {cloneElement(sidebarButton, { open })}
                    <div className={classes.header}>
                        <Typography
                            component={RouterLink}
                            to="/"
                            className={classes.logo}
                        >
                            <img src={LogoHeaderCsob} alt="LogoHeaderCsob" />
                        </Typography>
                    </div>
                    {whichUsername !== 'guest' ? (
                        <>
                            <div className={classes.indicator}>
                                <LoadingIndicator />
                            </div>
                            <Divider
                                className={classes.divider}
                                orientation="vertical"
                            />
                            {cloneElement(userMenu, { logout })}
                        </>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                color="inherit"
                                component={RouterLink}
                                to="/login"
                                className={classes.button}
                                size="small"
                            >
                                Login
                            </Button>
                            <Button
                                variant="contained"
                                color="inherit"
                                className={classes.button}
                                component={RouterLink}
                                to="/signup"
                                size="small"
                            >
                                Signup
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
};

AuthenticatedAppBar.defaultProps = {
    userMenu: <ApiHubUserMenu />,
    languagesMenu: <ApiHubLanguageSwitcher />,
    sidebarButton: <SidebarButton />,
};

const useStyles = makeStyles(
    theme => ({
        appBar: {
            background: theme.palette.common.white,
        },
        toolbar: {
            paddingRight: 24,
        },
        header: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            minWidth: '156px',
        },
        divider: {
            alignSelf: 'stretch',
            backgroundColor: theme.palette.secondary.bgDarkBlue,
            height: 'auto',
            width: '1px',
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        link: {
            fontWeight: 'bold',
            color: theme.palette.secondary.lightBlue,
            textDecoration: 'none',
            marginLeft: theme.spacing(1),
            outline: 'none',
            '&:hover': {
                textDecoration: 'none',
                outline: 'none',
                color: theme.palette.secondary.darkBlue,
            },
        },
        linkGuest: {
            fontWeight: 'bold',
            color: theme.palette.secondary.lightBlue,
            textDecoration: 'none',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2.5),
            outline: 'none',
            '&:hover': {
                textDecoration: 'none',
                outline: 'none',
                color: theme.palette.secondary.darkBlue,
            },
        },
        indicator: {
            color: theme.palette.secondary.lightBlue,
            '&:hover': {
                color: theme.palette.secondary.darkBlue,
            },
        },
        logo: {},
        button: {
            background: 'transparent',
            fontWeight: 'bold',
            fontSize: '15px',
            color: theme.palette.secondary.lightBlue,
            '&:hover': {
                background: theme.palette.secondary.lightGrayBlue,
            },
        },
    }),
    {
        name: 'HealthcareAuthenticatedAppBar',
    }
);
