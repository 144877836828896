import React from 'react';
import { theme as mytheme } from '../theme';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthState } from 'ra-core';
import { Redirect } from 'react-router';

import { UnAuthenticatedLayoutWithTheme } from '../layout';
import BannerCsob from '../ui/BannerCsob.jpg';
import CardImg1 from '../ui/image/CardImg1.jpg';
import CardImg2 from '../ui/image/CardImg2.jpg';
import CardImg3 from '../ui/image/CardImg3.jpg';

export const LandingPage = () => {
    const classes = useStyles();
    const { loading, authenticated } = useAuthState();

    if (loading) {
        return null;
    }
    const isGuest = localStorage.getItem('isGuest');
    if (authenticated && isGuest === 'false') {
        return <Redirect to="/apis" />;
    }

    return (
        <UnAuthenticatedLayoutWithTheme showActions>
            <Box className={classes.bannerContainer}>
                <Container maxWidth="lg">
                    <Typography variant="h3" className={classes.bannerContent}>
                        <span className={classes.bannerText}>
                            The building blocks
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;for API management
                        </span>
                        <img src={BannerCsob} alt="Banner" />
                    </Typography>
                </Container>
            </Box>

            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cards}
                >
                    <Grid item xs={12} md={4} sm={6} className={classes.item}>
                        <Card className={classes.card} color="inherit">
                            <CardContent>
                                <Link
                                    component={RouterLink}
                                    to="/apis"
                                    className={classes.link}
                                >
                                    <img src={CardImg1} alt="Documentation" />
                                    <Typography
                                        component="h4"
                                        gutterBottom
                                        variant="h5"
                                    >
                                        Documentation
                                    </Typography>
                                    <Typography>
                                        The documentation describes individual
                                        services and the process how to use them
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} className={classes.item}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Link
                                    component={RouterLink}
                                    to="/signup"
                                    className={classes.link}
                                >
                                    <img src={CardImg2} alt="Registration" />
                                    <Typography
                                        component="h4"
                                        gutterBottom
                                        variant="h5"
                                    >
                                        Registration
                                    </Typography>
                                    <Typography>
                                        Create an account and start using our
                                        open banking APIs
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} className={classes.item}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Link
                                    href="https://www.csob.cz/portal/csob/otevrene-bankovnictvi-csob/pro-vyvojare/seznam-api"
                                    target="_blank"
                                    className={classes.link}
                                >
                                    <img src={CardImg3} alt="List of APIs" />
                                    <Typography
                                        component="h4"
                                        gutterBottom
                                        variant="h5"
                                    >
                                        List of APIs
                                    </Typography>
                                    <Typography>
                                        Explore published APIs, find out which
                                        ones you may benefit from and how
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} className={classes.item}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Link
                                    component={RouterLink}
                                    to="/howto"
                                    className={classes.link}
                                >
                                    <img src={CardImg3} alt="List of APIs" />
                                    <Typography
                                        component="h4"
                                        gutterBottom
                                        variant="h5"
                                    >
                                        How To
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} className={classes.item}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Link
                                    href="#"
                                    target="_blank"
                                    className={classes.link}
                                >
                                    <img src={CardImg3} alt="List of APIs" />
                                    <Typography
                                        component="h4"
                                        gutterBottom
                                        variant="h5"
                                    >
                                        Drop us a line
                                    </Typography>
                                    <Typography>
                                        Need a question answered before signing
                                        up?
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </UnAuthenticatedLayoutWithTheme>
    );
};

const useStyles = makeStyles(theme => ({
    bannerContainer: {
        position: 'relative',
        backgroundColor: mytheme.palette.secondary.darkBlue,
    },
    bannerContent: {
        '& img': {
            display: 'flex',
            width: '100%',
        },
    },
    bannerText: {
        position: 'absolute',
        top: '110px',
        paddingLeft: theme.spacing(20),
        color: theme.palette.common.white,
        fontWeight: 300,
        [theme.breakpoints.down('md')]: {
            fontSize: '40px',
            top: '70px',
            paddingLeft: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',
            top: '45px',
            paddingLeft: theme.spacing(5),
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            top: '40px',
            paddingLeft: theme.spacing(5),
        },
    },
    button: {},
    cards: {
        alignItems: 'stretch',
        justifyContent: 'center',
        '& > li': {
            display: 'flex',
            alignItems: 'stretch',
        },
    },
    item: {
        marginTop: theme.spacing(4),
    },
    card: {
        borderRadius: theme.spacing(1),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        '& > :first-child': {
            flex: 1,
            paddingTop: theme.spacing(4),
        },
        '& h4': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        '& img': {
            width: '100%',
        },
    },
    link: {
        color: mytheme.palette.secondary.darkBlue,
        '& h4': {
            color: mytheme.palette.secondary.lightBlue,
        },
        '&:hover': {
            color: mytheme.palette.secondary.darkBlue,
            textDecoration: 'none',
            outline: 'none',
            '& h4': {
                color: mytheme.palette.secondary.darkBlue,
            },
        },
    },
}));
