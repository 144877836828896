import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import LogoHeaderCsob from '../ui/LogoHeaderCsob.png';

export const Footer = ({ withLogo = true, ...rest }) => {
    const classes = useStyles(rest);

    return (
        <footer className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cards}
                >
                    <Grid item xs={12} md={6} sm={4}>
                        <Typography
                            component={RouterLink}
                            to="/"
                            className={classes.logo}
                        >
                            <img src={LogoHeaderCsob} alt="LogoHeaderCsob" />
                        </Typography>
                        <Typography className={classes.company}>
                            Copyright © 2022, Československá obchodní banka,
                            a.s.
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4}>
                        <Link
                            href="https://www.csob.cz/portal/bezpecnost"
                            target="_blank"
                            className={classes.link}
                            variant="body1"
                        >
                            Security
                        </Link>
                        <Link
                            className={classes.link}
                            variant="body1"
                            href="https://www.csob.cz/portal/kontakty"
                            target="_blank"
                        >
                            Contacts
                        </Link>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4}>
                        <Link
                            className={classes.link}
                            variant="body1"
                            href="https://www.csob.cz/portal/csob/o-csob-a-skupine"
                            target="_blank"
                        >
                            About ČSOB Group
                        </Link>
                        <Link
                            className={classes.link}
                            variant="body1"
                            href="https://www.csob.cz/portal/podminky-pouzivani"
                            target="_blank"
                        >
                            Cookies a Terms of Use
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

const useStyles = makeStyles(
    theme => ({
        container: {},
        root: {
            backgroundColor: theme.palette.secondary.darkWhite,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginTop: theme.spacing(8),
            alignItems: 'center',
        },
        company: {
            color: theme.palette.secondary.darkGray,
            fontWeight: theme.typography.fontWeightBold,
            flexGrow: 1,
        },
        link: {
            display: 'block',
            fontWeight: 'bold',
            color: theme.palette.secondary.lightBlue,
            textDecoration: 'none',
            outline: 'none',
            marginRight: theme.spacing(2),
            '&:hover': {
                color: theme.palette.secondary.darkBlue,
            },
        },
        logo: {
            '& img': {
                marginRight: theme.spacing(1),
            },
        },
    }),
    {
        name: 'HealthcareFooter',
    }
);
