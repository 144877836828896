import React from 'react';
import { ApiHubLayout } from 'layer7-apihub';
import { theme } from '../theme';
import { makeStyles } from '@material-ui/core';

import { AuthenticatedAppBar } from './AuthenticatedAppBar';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';

export const AuthenticatedLayout = ({ children, ...rest }) => {
    const classes = useLayoutStyles();

    return (
        <ApiHubLayout
            appBar={AuthenticatedAppBar}
            {...rest}
            theme={theme}
            sidebar={Sidebar}
        >
            <div className={classes.innerContent}>{children}</div>
            <Footer />
        </ApiHubLayout>
    );
};

const useLayoutStyles = makeStyles(
    theme => ({
        innerContent: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    {
        name: 'HealthcareAuthenticatedLayout',
    }
);
