import React from 'react';
import { theme as mytheme } from '../theme';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

import { UnAuthenticatedLayoutWithTheme } from '../layout';
import { LoginForm } from './LoginForm';
import Container from '@material-ui/core/Container';

export const LoginPage = props => {
    const classes = useStyles();
    localStorage.setItem('useredirect', false);

    return (
        <UnAuthenticatedLayoutWithTheme showActions={false}>
            <Container className={classes.container} maxWidth="lg">
                <Grid container className={classes.content}>
                    <Grid item xs={12} md={6} className={classes.login}>
                        <Card className={classes.loginCard}>
                            <CardContent>
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    className={classes.loginTitle}
                                >
                                    Login
                                </Typography>
                            </CardContent>
                            <Divider />
                            <CardContent className={classes.loginContent}>
                                <LoginForm />
                            </CardContent>
                            <Divider />
                            <CardContent className={classes.loginContent}>
                                <Typography align="center" gutterBottom>
                                    Don't have an account?
                                </Typography>
                                <Button
                                    component={RouterLink}
                                    to="/signup"
                                    variant="outlined"
                                    className={classes.signUp}
                                >
                                    Join Now
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </UnAuthenticatedLayoutWithTheme>
    );
};

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            background: mytheme.palette.secondary.bgLightBlue,
        },
    },
    container: {},
    content: {
        justifyContent: 'center',
        marginTop: theme.spacing(8),
        flex: 3,
    },
    loginCard: {
        backgroundColor: theme.palette.common.white,
    },
    login: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: mytheme.palette.secondary.darkWhite,
    },
    loginTitle: {
        color: mytheme.palette.secondary.darkBlue,
        display: 'flex',
        justifyContent: 'center',
    },
    loginContent: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& button': {},
    },
    signUp: {
        borderRadius: theme.spacing(4),
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(2),
    },
}));
