import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import LogoHeaderCsob from '../ui/LogoHeaderCsob.png';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export const Header = props => {
    const classes = useAppbarStyles(props);
    const { showActions, ...rest } = props;
    const [anchorEl, setAnchorEl] = React.useState(false);
    const handleClick = () => {
        setAnchorEl(true);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };
    const myUser = JSON.parse(localStorage.getItem('userContext'));
    if (myUser) {
        console.log(myUser);
    }
    return (
        <Container className={classes.container} maxWidth="lg">
            <AppBar
                component="header"
                className={classes.root}
                position="static"
                color="transparent"
                elevation={0}
                {...rest}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        component={RouterLink}
                        to="/"
                        className={classes.logo}
                    >
                        <img src={LogoHeaderCsob} alt="LogoHeaderCsob" />
                    </Typography>
                    <Typography variant="h5" className={classes.title}>
                        <span>Developer Network</span>
                    </Typography>
                    {showActions ? (
                        myUser === null ||
                        myUser?.userDetails?.username === 'guest' ? (
                            <>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    component={RouterLink}
                                    to="/login"
                                    className={classes.button}
                                    size="small"
                                >
                                    Login
                                </Button>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    className={classes.button}
                                    component={RouterLink}
                                    to="/signup"
                                    size="small"
                                >
                                    Signup
                                </Button>
                                <div>
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        onClick={handleClick}
                                        className={classes.mobileButton}
                                    >
                                        <MenuIcon />
                                    </Button>
                                    <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <StyledMenuItem
                                            component={RouterLink}
                                            to="/login"
                                            onClick={handleClose}
                                        >
                                            Login
                                        </StyledMenuItem>
                                        <StyledMenuItem
                                            component={RouterLink}
                                            to="/signup"
                                            onClick={handleClose}
                                        >
                                            Signup
                                        </StyledMenuItem>
                                    </StyledMenu>
                                </div>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    component={RouterLink}
                                    to="/apis"
                                    className={classes.button}
                                    size="small"
                                >
                                    Go to APIs
                                </Button>
                                <div>
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        onClick={handleClick}
                                        className={classes.mobileButton}
                                    >
                                        <MenuIcon />
                                    </Button>
                                    <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <StyledMenuItem
                                            component={RouterLink}
                                            to="/apis"
                                            onClick={handleClose}
                                        >
                                            Go to APIs
                                        </StyledMenuItem>
                                    </StyledMenu>
                                </div>
                            </>
                        )
                    ) : null}
                </Toolbar>
            </AppBar>
        </Container>
    );
};

const useAppbarStyles = makeStyles(
    theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        root: {
            background: 'transparent',
        },
        toolbar: {
            alignItems: 'center',
            paddingRight: '0',
            paddingLeft: '0',
        },
        title: {
            paddingLeft: theme.spacing(3),
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.secondary.darkBlue,
            '& span': {
                maxWidth: 100,
                fontWeight: 300,
            },
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                fontSize: '18px',
                paddingLeft: theme.spacing(1.5),
            },
        },
        button: {
            background: 'transparent',
            fontWeight: 'bold',
            fontSize: '15px',
            color: theme.palette.secondary.lightBlue,
            '&:hover': {
                background: theme.palette.secondary.lightGrayBlue,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        mobileButton: {
            background: 'transparent',
            '& .MuiListItemIcon-root': {
                paddingTop: theme.spacing(1),
                paddingRight: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                paddingLeft: theme.spacing(1),
                borderRadius: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        menuIcon: {
            borderRadius: '30%',
        },
        link: {
            fontWeight: 'bold',
            color: theme.palette.secondary.lightBlue,
            textDecoration: 'none',
            outline: 'none',
            marginRight: theme.spacing(2),
            '&:hover': {
                textDecoration: 'none',
                outline: 'none',
                color: theme.palette.secondary.darkBlue,
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        lenguage: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        logo: {
            display: 'flex',
        },
    }),
    {
        name: 'HealthcareHeader',
    }
);
