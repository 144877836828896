import React from 'react';
import {
    ResetPasswordForm,
    ResetPasswordConfirm,
    useResetPassword,
} from 'layer7-apihub';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { theme as mytheme } from '../theme';

import { AuthenticationLayout } from './AuthenticationLayout';

export const ResetPasswordPage = props => {
    const classes = useStyles(props);
    const [username, setUsername] = useResetPassword();

    const handleSubmit = ({ username }) => {
        setUsername(username);
    };

    return (
        <AuthenticationLayout>
            <Container className={classes.container} maxWidth="lg">
                <Grid container className={classes.content}>
                    <Grid item xs={12} md={6} className={classes.form}>
                        <Card className={classes.formCard}>
                            <CardContent className={classes.resetPasswordTitle}>
                                <Typography component="h1" variant="h4">
                                    Reset Password
                                </Typography>
                            </CardContent>
                            <Divider />
                            <CardContent className={classes.resetPassword}>
                                {username ? (
                                    <ResetPasswordConfirm {...props} />
                                ) : (
                                    <>
                                        <Typography
                                            className={
                                                classes.resetPasswordText
                                            }
                                            gutterBottom
                                        >
                                            Please fill this form to reset your
                                            password.
                                        </Typography>
                                        <ResetPasswordForm
                                            onSubmit={handleSubmit}
                                            toolbarProps={{
                                                button: {
                                                    color: 'secondary',
                                                    variant: 'contained',
                                                    size: 'medium',
                                                },
                                            }}
                                            {...props}
                                        />
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </AuthenticationLayout>
    );
};

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            background: mytheme.palette.secondary.bgLightBlue,
        },
    },
    container: {},
    content: {
        justifyContent: 'center',
        marginTop: theme.spacing(8),
        flex: 1,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formCard: {
        backgroundColor: theme.palette.common.white,
    },
    resetPasswordTitle: {
        textAlign: 'center',
        color: mytheme.palette.secondary.darkBlue,
    },
    resetPasswordText: {
        color: mytheme.palette.secondary.darkGray,
    },
    resetPassword: {
        padding: theme.spacing(6),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
    },
}));
